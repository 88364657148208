@import url(https://fonts.googleapis.com/css?family=Lato:300italic);

html,
body
{
    width:  100%;
    height: 100%;
}

body
{
    background:  #ee9ca7;
    font-family: 'Lato', sans-serif;
}

#cake
{
    display:  block;
    position: relative;
    margin:   -10em auto 0 auto;
}

/** Candle **/

.velas
{
    background:          #ffffff;
    border-radius:       10px;
    position:            absolute;
    top:                 228px;
    left:                50%;
    margin-left:         -2.4px;
    margin-top:          -8.33333333px;
    width:               5px;
    height:              35px;
    transform:           translateY(-300px);
    backface-visibility: hidden;
    animation:           in 500ms 6s ease-out forwards;
}

.velas:after,
.velas:before
{
    background: rgba(255, 0, 0, 0.4);
    content:    "";
    position:   absolute;
    width:      100%;
    height:     2.22222222px;
}

.velas:after
{
    top:  25%;
    left: 0;
}

.velas:before
{
    top:  45%;
    left: 0;
}

/** Fire **/

.fuego
{
    border-radius: 100%;
    position:      absolute;
    top:           -20px;
    left:          50%;
    margin-left:   -2.6px;
    width:         6.66666667px;
    height:        18px;
}

.fuego:nth-child(1)
{
    animation: fuego 2s 6.5s infinite;
}

.fuego:nth-child(2)
{
    animation: fuego 1.5s 6.5s infinite;
}

.fuego:nth-child(3)
{
    animation: fuego 1s 6.5s infinite;
}

.fuego:nth-child(4)
{
    animation: fuego 0.5s 6.5s infinite;
}

.fuego:nth-child(5)
{
    animation: fuego 0.2s 6.5s infinite;
}

/** Fire Animation **/

@keyframes fuego
{
    0%, 100%
    {
        background: rgba(254, 248, 97, 0.5);
        box-shadow: 0 0 40px 10px rgba(248, 233, 209, 0.2);
        transform:  translateY(0) scale(1);
    }
    50%
    {
        background: rgba(255, 50, 0, 0.1);
        box-shadow: 0 0 40px 20px rgba(248, 233, 209, 0.2);
        transform:  translateY(-20px) scale(0);
    }
}

@keyframes in
{
    to
    {
        transform: translateY(0);
    }
}

.text
{
    color:      #8b6a60;
    text-align: center;
}

.text h1
{
    font-size: 2em;
}

.text p
{
    font-size: 1.8em;
}

.download-button
{
    display:          inline-block;
    padding:          10px 20px;
    font-size:        16px;
    color:            #ffffff;
    background-color: #8b6a60;
    text-align:       center;
    text-decoration:  none;
    border-radius:    5px;
    font-weight:      bold;
    border:           none;
    cursor:           pointer;
    transition:       background-color 0.3s;
    margin-top:       20px;
}

.download-button:hover
{
    background-color: #7a5f54;
}


/** Explosion **/
@keyframes explode
{
    from
    {
        transform: scale(1);
        opacity:   1;
    }
    to
    {
        transform: scale(3);
        opacity:   0;
    }
}

.explode
{
    animation: explode 0.5s ease-out forwards;
}
